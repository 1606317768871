.userDisplay {
  margin: auto;
  width: 80%;
  max-width: 500px;
  padding: 10px;
  margin-top: 40px;
}

.dateRow {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.dateRow .startDate {
  margin: 10px;
}

.dateRow .endDate {
  margin: 10px;
}
.buttonRow {
  display: flex;
  justify-content: center;
}

.linkRow {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}


.tableWrapper {
  min-width: 400px;
  width: 80%;
  max-width: 1500px;
  margin: auto;
  margin-top: 50px;
  background-color: white;
  margin-bottom: 20px;
}