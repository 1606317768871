body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inputForm {
  margin: auto;
  width: 80%;
  max-width: 500px;
  padding: 10px;
  margin-top: 40px;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

#root {
  overflow: auto;
  background-image: url('./back3.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
}

.ReactTable {
  max-height: 50vh;
}